import React from 'react';
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import * as api from "../api";
import {parseTime, Resource} from "../utils/helpers";
import {Pagination} from "./index";
import {TimePicker} from "antd";
import moment from "moment";

import "antd/dist/antd.css";

const timeFormat = 'HH:mm';

class MediaPlanFormFaceSwapFormChange extends React.Component {

  constructor(props) {
    super(props);

    const data = props.data || {};

    this.state = {
      data: {
        id: data.id || null,
        file: data?.file ? {id: data?.file, name: data?.filename, type: data?.media_type} : null,
        layout_file: data?.layout_file ? {id: data?.file, name: data?.layout_name} : null,
        duration: (!!data.duration) ? parseTime(data.duration) : parseTime("00:30:00"),
      },
      media: {
        items: [],
        total: 0,
        page: 1,
        limit: 10,
        pages: 1,
        maxPages: 5,
      },
      target: null,
      error: {
        form: ""
      },
      pending: false
    };
  }

  componentDidMount() {
    api.Media.FileList({
      page: this.state.media.page,
      limit: this.state.media.limit
    })
      .then((res) => {

        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
  }

  fileFilter = (item) => item.status.state === "ready";

  handleMediaFilePageChange = (page) => {
    api.Media.FileList({
      page: page,
      limit: this.state.media.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
      .catch(() => {
      });
  };

  handleSelectFile = (e, target, item) => {
    const patch = {
      data: {
        ...this.state.data,
        [target]: {id: item.meta.id, name: item.meta.name, type: item.meta.type},
      },
      target: null
    }
    this.setState(patch)
  };

  handleDateChange = (name, value) => {
    let date = !!value ? value.toDate() : null;
    this.setState({data: {...this.state.data, [name]: date}})
  };

  handleSelectTarget = (target) => {
    this.setState({target: target})
  }

  handleUnselectFile = (target) => {
    this.setState({data: {...this.state.data, [target]: null}})
  };

  contentFilter = (item) => {
    if (this.state.target === "layout_file") return item.meta.type !== "video"
    if (this.state.target === "file") return item.meta.type === "video"
    return true
  }

  handleCancel = (e) => {
    this.props.onCancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({pending: true});
    let data = {...this.state.data};

    data.duration = new Date(data.duration).toLocaleTimeString();

    this.props.onSubmit(data)
  };

  handleDisabled = () => {
    return (
      this.state.pending || !this.state.data.file || !this.state.data.layout_file
    )
  };

  render() {
    return (
      <form>

        <div className={`form-group ${!!this.state.error.form ? "has-danger" : ""}`}>
          {this.state.error.form && <span className="badge badge-danger">{this.state.error.form}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nameInput"
                 className="col-form-label">{I18n.t('key_DeviceFaceSwapScheduleFormCreate_Duration')}</label>
          <div>
            <TimePicker format={timeFormat}
                        inputReadOnly={true}
                        allowClear={false}
                        value={moment(this.state.data.duration, timeFormat)}
                        defaultValue={moment('00:00', timeFormat)}
                        defaultOpenValue={moment('00:00', timeFormat)}
                        onChange={val => this.handleDateChange("duration", val)}/>
          </div>
        </div>

        <div className="mb-5">
          {!this.state.target && (
            <div>
              {[
                {name: "Контент для показа", target: "file"},
                {name: "Макет для замены лица", target: "layout_file"}
              ].map((item, index) => (
                <div className="mb-3" key={index}>
                  <div className="h6">{item.name}</div>
                  <div>
                    {!!this.state.data[item.target]
                      ? (
                        <div>
                          {this.state.data[item.target].name}&nbsp;<i
                          className="fa fa-times text-danger cursor-pointer"
                          onClick={() => this.handleUnselectFile(item.target)}/>
                        </div>
                      )
                      : (
                        <div>
                          <button className="btn btn-primary" onClick={() => this.handleSelectTarget(item.target)}>
                            Выбрать
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div>
              ))}
            </div>
          )}

          {!!this.state.target && (
            <div>
              <div className="list-group list-group-flush">
                {
                  (this.state.media.items).filter(this.contentFilter).map((item, index) => {
                    return (
                      <div key={index}
                           className="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
                           onClick={e => this.handleSelectFile(e, this.state.target, item)}>

                        <div style={{verticalAlign: "middle", maxWidth: "300px"}}
                             className="text-truncate">{item.meta.name}</div>

                        <div style={{verticalAlign: "middle"}}>
                                <span className="badge badge-info" style={{width: "60px"}}>
                                  {Resource.MemoryBytesToHumanSize(item.meta.size, 2)}
                                </span>
                        </div>

                      </div>
                    )
                  })
                }
              </div>

              <div className="mt-4">
                <Pagination maxPages={this.state.media.maxPages}
                            currentPage={this.state.media.page}
                            numPages={this.state.media.pages}
                            change={this.handleMediaFilePageChange}/>
              </div>

            </div>
          )}
        </div>

        <div className="clearfix my-5">
          <div className="float-left">
            <button className="btn btn-secondary"
                    onClick={this.handleCancel}>
              {I18n.t('key_Cancel')}
            </button>
          </div>
          <div className="float-right">
            <button className="btn btn-primary"
                    onClick={this.handleSubmit}
                    disabled={this.handleDisabled()}>
              {I18n.t('key_Apply')}
            </button>
          </div>
        </div>

      </form>
    )
  }
}

MediaPlanFormFaceSwapFormChange.propTypes = {
  name: PropTypes.string,
  limit: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MediaPlanFormFaceSwapFormChange;
